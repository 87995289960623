/* about start */

.about-section{
    min-height: 100vh;
    background: #f2f2f2;
}


.about-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
}

.about-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}

@media (max-width: 767px){
    .about-social-icon{
        margin-bottom: 2rem;
    }
}

/* about end */