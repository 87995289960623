/* header start */

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header-section{
    background: #fff;
    position: relative;
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.header-section:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(45deg, #ff0081, #6827b0); */
    background: linear-gradient(90deg, #003d7e, deepskyblue);
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    transform: scaleX(1.5);
}

.header-section .header-content{
    position: relative;
    z-index: 1;
    margin: 0 auto;
    max-width: 900px;
    text-align: center;
}

.header-section .header-content h2{
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 3em;
}

.header-section .header-content p{
    color: #fff;
    font-size: 1.2em;
}

@media (max-width: 768px){
    .header-section .header-content h2{
        font-size: 2em;
    }
}

/* header end */