.footer-section ul {
    margin: 0px;
    padding: 0px;
}

.footer-section {
    background: linear-gradient(90deg, #003d7e, deepskyblue);;
    position: relative;
}

.footer-cta {
    border-bottom: 1px solid #373636;
}

.single-cta i {
    color: #0795fe;
    font-size: 30px;
    float: left;
    margin-top: 8px;

}

.cta-text {
    padding-left: 60px;
    /* display: inline-block; */
}

.cta-text h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
}

.cta-text a {
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    transition: 0.2s ease-in-out;
}

.cta-text a:hover{
    color: darkgrey;
}

.footer-content {
    position: relative;
    z-index: 2;
}

.footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
}

.footer-logo {
    margin-bottom: 30px;
}

.footer-logo img {
    max-width: 200px;
}

.footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #fff;
    line-height: 28px;
}

.footer-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
}

.footer-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
}

.footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}

.facebook-bg{
    background: #3B5998;
}

.instagram-bg{
    background: #e1306c;
}

.youtube-bg{
    background: #ff0000;
}

.tiktok-bg{
    background: #000000;
}

.linkedin-bg{
    background: #0077b5;
}

.footer-widget-heading h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
}

.footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: #0795fe;
}

.footer-widget ul li {
    display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
}

.footer-widget ul li a:hover{
    color: darkgrey;
}

.footer-widget ul li a {
    color: #fff;
    text-transform: capitalize;
    text-decoration: none;
    transition: 0.2s ease-in-out;
}

.subscribe-form {
    position: relative;
    overflow: hidden;
}

.subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #fff;
    border: 1px solid #fff;
    color: black;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    background: #0795fe;
    padding: 13px 20px;
    border: 1px solid #0795fe;
    top: 0;
}

.subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
}

.copyright-area{
    background: #02adee;
    padding: 25px 0;
}

.copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #fff;
}

.copyright-text p a{
    color: #ff5e14;
}

.footer-menu li {
    display: inline-block;
    margin-left: 20px;
}

.footer-menu li:hover a{
    color: darkgrey;
}

.footer-menu li a {
    font-size: 14px;
    color: white;
    text-decoration: none;
    transition: 0.2s ease-in-out;
}

@media (max-width: 767px){
    .single-cta{
        margin-bottom: 2rem;
    }
}