*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header{
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #02adee;
    box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
    padding: 0 100px;
    transition: 0.6s;
}

header ul {
    margin: 0px;
    padding: 0px;
}

header .logo-nav{
    /* color: #fff;
    fontsize: 1.8em;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px; */
    height: 100%;
    max-width: 60px;
    height: auto;
}

header .navigation{
    position: relative;
    line-height: 75px;
}

header .navigation .menu{
    position: relative;
    display: flex;
    justify-content: center;
    list-style: none;
    user-select: none;
}

.menu-item > a{
    color: #fff;
    font-size: 1em;
    text-decoration: none;
    margin: 20px;
    padding: 25px 0;
}

.menu-item > a:hover{
    color: #191f36;
    transition: 0.3s;
}

.menu-item .sub-menu{
    position: absolute;
    background: #02adee;
    top: 74px;
    line-height: 40px;
    list-style: none;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
    pointer-events: none;
    transform: translateY(20px);
    opacity: 0;
    transition: 0.3s;
    transition-property: transform,opacity;
}

.menu-item:hover .sub-menu{
    pointer-events: all;
    transform: translateY(0);
    opacity: 1;
}

.menu-item .sub-menu .sub-item{
    position: relative;
    padding: 7px 0;
    cursor: pointer;
    box-shadow: inset 0px -30px 5px -30px rgba(255, 255, 255, 0.2);
}

.menu-item .sub-menu .sub-item a{
    color: #fff;
    font-size: 1em;
    text-decoration: none;
    padding: 15px 20px;
}

.menu-item .sub-menu .sub-item:hover{
    background: #4080ef;
}

.menu-item .sub-menu .sub-item:hover:last-child:hover{
    border-radius: 0 0 8px 8px;
}

.more .more-menu{
    position: absolute;
    background: #02adee;
    list-style: none;
    top: 0;
    left: 100%;
    white-space: nowrap;
    border-radius: 0 8px 8px 8px;
    overflow: hidden;
    pointer-events: none;
    transform: translateY(20px);
    opacity: 0;
    transition: 0.3s;
    transition-property: transform, opacity;
}

.more:hover .more-menu{
    pointer-events: all;
    transform: translateY(0);
    opacity: 1;
}

.more .more-menu .more-item{
    padding: 7px 0;
    box-shadow: inset 0px -30px 5px -30px rgba(255, 255, 255, 0.2);
    transition: 0.3s;
}

.more .more-menu .more-item:hover{
    background: #4080ef;
}

.menu-btn{
    display: none;
}

@media (max-width: 1300px){
    header .navigation .menu{
        position: fixed;
        display: block;
        background: #23252b;
        min-width: 350px;
        height: 100vh;
        top: 0;
        right: -100%;
        padding: 90px 50px;
        visibility: hidden;
        overflow-y: auto;
        transition: 0.5s;
        transition-property: right, visibility;
    }
    header .navigation .menu.active{
        right: 0;
        visibility: visible;
    }
    .menu-item{
        position: relative;
    }
    .menu-item .sub-menu{
        opacity: 1;
        position: relative;
        top: 0;
        transform: translateX(10px);
        transform: translateX(0);
        background: rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        overflow: hidden;
        display: none;
    }
    .menu-item:hover .sub-menu{
        transform: translateX(10px);
    }
    .menu-item .sub-menu .sub-item{
        box-shadow: none;
    }
    .menu-item .sub-menu .sub-item:hover{
        background: none;
    }
    .menu-item .sub-menu .sub-item a:hover{
        color: #4080ef;
        transition: 0.3s;
    }
    .more .more-menu{
        opacity: 1;
        position: relative;
        left: 0;
        transform: translateY(0);
        background: rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        display: none;
    }
    .more .more-menu .more-item{
        box-shadow: none;
    }
    .more .more-menu .more-item:hover{
        background: none;
    }
    .more .more-menu .more-item a{
        margin-left: 20px;
    }
    .close-btn{
        position: absolute;
        background: url(../../../public/assets/images/icon/close.png)no-repeat;
        width: 40px;
        height: 40px;
        background-size: 25px;
        background-position: center;
        top: 0;
        left: 0;
        margin: 25px;
        cursor: pointer;
    }
    .menu-btn{
        background: url(../../../public/assets/images/icon/menu-bar.png)no-repeat;
        width: 40px;
        height: 40px;
        background-size: 30px;
        background-position: center;
        cursor: pointer;
        display: block;
    }
    header{
        padding: 15px 20px;
    }
}